.werkvormen {
  .werkvormen-title {
    margin-top: 30px;
    width: 400px;
    max-width: 100%;
  }

  .button-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .button {
    width: 400px;
  }

  @media (min-width: 768px) {
    .werkvormen-title, .button {
      width: 550px;
    }
  }

  @media (min-width: 992px) {
    .werkvormen-title, .button {
      width: 675px;
    }
  }

  @media (min-width: 1200px) {
    .werkvormen-title, .button {
      width: 800px;
    }
  }
}

.werkvormen-image-box {
  margin: 50px auto;
  max-width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;

    &:last-child {
      margin-left: 20px;
    }
  }
}

.voorstellen {
  max-width: 45vw;
  p {
    text-align: justify;
  }

  @media (min-height: 468px) {
    margin-bottom: 100px;
  }
}

.strategy-block {
  max-width: 60%;
  margin: 0 auto;

  &.justify {
    text-align: justify;
  }
}

.geluidsfragmenten-wrapper {
  padding-top: 30px;
  
  .geluidsfragmenten-grid {
    display: grid;
    grid-gap: 45px;
    grid-template-columns: repeat(6, 1fr);  
    padding-bottom: 30px;
  
    @media (min-width: 992px) {
        grid-template-columns: repeat(8, 1fr);
    }
  
    .grid-item {
      padding: 20px;
      font-size: 30px;
      text-align: center;
      background-color: #ffffff;
      cursor: pointer;
  
      div {
        width: 100%;
        height: 100%;
  
        &.playing {
          transform: scale(2);
          font-weight: bold;
        }
      }
    }
  }

  p {
    margin-bottom: 9vh;
  }
}

.geluidsfragmenten-image {
  position: fixed;
  background: url('../../assets/images/werkvormen/werkvormen_5.png') center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 250px;
  bottom: 0;
  right: 300px;

  @media (max-height: 768px) {
    height: 150px;
    right: 200px;
  }

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-height: 500px) {
    display: none;
  }
}

.upside-down {
  position: absolute;
  background: url('../../assets/images/werkvormen/werkvormen_6.png') bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 15vw;
  height: 40vh;
  bottom: 0;
  left: 32px;

  @media (max-width: 1600px) {
    background: url('../../assets/images/werkvormen/werkvormen_6.png') center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
    left: -50px;
    bottom: 30vh;
  }
}

.lamp {
  position: absolute;
  background: url('../../assets/images/werkvormen/werkvormen_7.png') bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20vw;
  height: 60vh;
  bottom: 0;
  right: 32px; 

  @media (max-width: 1600px) {
    display: none;
  }
}