@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeue.ttf') format('truetype'),
       url('../../assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
       url('../../assets/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeue-Italic.ttf') format('truetype'),
       url('../../assets/fonts/HelveticaNeue-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeue-BoldItalic.ttf') format('truetype'),
       url('../../assets/fonts/HelveticaNeue-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}


$base-font-size: 26px;
$base-font-family: HelveticaNeue;

body {
  font-size: $base-font-size;
  @media (max-width: 992px) {
    font-size: calc(#{$base-font-size} - 8px);
  }
  font-family: $base-font-family;
  color: $text-color;
}

h1 {
  font-size: 3.8rem;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }
}