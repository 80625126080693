//Colors
$black: black;
$light-grey: #dddddd;
$lightest-grey: #eeeeee;
$white: white;
$red: rgb(238, 45, 49);
$yellow: rgb(255, 221, 0);
$green: rgb(44, 179, 74);
$blue: rgb(0, 161, 228);
$beige: #fdf7eb;

$text-color: $black;
$error-color: $red;
$background-color-content: $beige;
$background-color: $beige;

body {
  background-color: $background-color;
}

.bg-red {
  background-color: $red;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-green {
  background-color: $green;
}

.bg-blue {
  background-color: $blue;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

.border-red {
  border: 3px solid $red;
}

.border-yellow {
  border: 3px solid $yellow;
}

.border-green {
  border: 3px solid $green;
}

.border-blue {
  border: 3px solid $blue;
}

.border-black {
  border: 3px solid $black;
}