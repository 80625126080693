.chest-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 40px;

  img {
    width: 250px;
    height: auto;

    @media (max-height: 500px) {
      width: 150px;
    }

    @media (max-height: 450px) {
      width: 100px;
    }
  }

  .button {
    display: block;
    margin-top: 10%;
    margin-bottom: 15%;
  }
}

.chest-detail-wrapper {
  margin-bottom: 50px;

  .slider-item {
    width: inherit !important;
    max-width: 100vw;

    .slider-card {
      border-radius: 20px;
      padding: 25px;

      .cat-logo-wrapper {
        display: flex;
        justify-content: center;

        .cat-logo {
          height: 28px;
          margin-bottom: 20px;

          @media (max-height: 668px) {
            height: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  img {
    height: 100%;
    max-height: 55vh;
    max-width: 100%;
  }
}