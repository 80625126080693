.content {
  width: 100vw;
  height: 100vh;
  position: relative;

  main {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);

    a {
      text-decoration: none;
      color: inherit;
    }

    .container {
      margin-right: auto;
      margin-left: auto;
      max-height: 100vh;

      @media (min-width: 768px) {
          width: 750px;
      }

      @media (min-width: 992px) {
          width: 970px;
      }
      @media (min-width: 1200px) {
          width: 1170px;
      }

      &.center {
        text-align: center;
      }
    }
  }
}
