.interactionButtons  {
  position: fixed;
  top: 0;
  right: 0;

  ul  {
    list-style: none;
    text-align: right;

    .new-line {
      display: none;
    }

    .bottom {
      position: fixed;
      bottom: 0;
    }

    li:last-of-type {
      button {
        margin-bottom: 0;
      }
    }

    button {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 280px;
      background-color: #fff;
      padding: 15px;
      border-style: solid none solid solid;
      border-width: 3px;
      border-color: black;
      border-radius: 15px 0 0 15px;
      cursor: pointer;
      z-index: 99;

      i {
        float: left;
        color: transparent;
        user-select: none;
        height: 50px;

        &.ear {
          background: url('../../assets/images/icons/voorlezen.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.skip {
          background: url('../../assets/images/icons/overslaan.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.next {
          background: url('../../assets/images/icons/dobbelsteen.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.flag {
          background: url('../../assets/images/icons/vlag.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.done {
          background: url('../../assets/images/icons/blij-hoofd.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.chest {
          background: url('../../assets/images/chest/chest.png') center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    .player {
      margin-right: 15px;
      margin-bottom: 15px;
      font-size: 0.8em;

      @media (max-width: 992px) and (max-height: 400px) {
        padding-top: 15px;
      }
    }

    @media (max-height: 711px) {
      button {
        margin-bottom: 5px;
        i{
          height: 30px;
        }
      }

      li:last-of-type {
        button {
          margin-bottom: 5px;
        }
      }

      .bottom {
        position: relative;
        margin-bottom: 5px;
      }
    }

    @media (max-width: 1068px) and (min-height: 555px) {
      .new-line {
        display: block;
      }

      button {
        width: 220px;
      }
    }

    @media (max-width: 992px) {
      button {
        width: 150px;
      }
    }
  }
}